import { createTheme as createBaseTheme } from '@portals/core/src/themes/sde/main';
import type { Theme } from '@portals/core/src/themes/themes';

const options = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 720,
      md: 1080,
      lg: 1440,
      xl: 1600,
      text: 980,
    },
  },
};

export function createTheme(...overrideTheme): Theme {
  return createBaseTheme(options, ...overrideTheme);
}

export const theme: Theme = createTheme();
