export interface sharedConfig {
  NEXT_PUBLIC_COOKIE_DOMAIN?: string;
  NEXT_PUBLIC_HOST?: string;
  NEXT_PUBLIC_GTM_ID?: string;
  NEXT_PUBLIC_GTM_AUTH?: string;
  NEXT_PUBLIC_GTM_ENV?: string;
  YELLOWMAPS_SYSTEM_PARTNER?: string;
  YELLOWMAP_TILE_URL?: string;
  YELLOWMAP_AUTOCOMPLETE_URL?: string;
  YELLOWMAPS_SECURITY_ID?: string;
  YELLOWMAP_API_URL?: string;
  LOGGING_ENDPOINT?: string;
  LOCALSTORAGE_BOOKMARKS?: string;
  FINANCE_CERTIFICATE_URL?: string;
  APP_CONTEXT?: string;
  SIP_API_PROXY?: string;
  BANK_LOGO_PATH?: string;
}

export type SharedConfigKey = keyof sharedConfig;

export abstract class Config {
  protected config: sharedConfig = {} as sharedConfig;
  public set(key: SharedConfigKey, value: string) {
    this.config[key] = value;
  }

  public get(key: SharedConfigKey) {
    return this.config[key] || undefined;
  }
}
export class ConfigProvider {
  public static setConfig(config: Config) {
    globalThis.appConfig = config;
  }

  public static getConfig(): Config {
    return globalThis.appConfig;
  }
}
