import { Config } from '../general/Config';

export class FrontendConfig extends Config {
  constructor() {
    super();

    this.config = {
      NEXT_PUBLIC_COOKIE_DOMAIN: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
      NEXT_PUBLIC_HOST: process.env.NEXT_PUBLIC_HOST,
      NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
      NEXT_PUBLIC_GTM_AUTH: process.env.NEXT_PUBLIC_GTM_AUTH,
      NEXT_PUBLIC_GTM_ENV: process.env.NEXT_PUBLIC_GTM_ENV,
      YELLOWMAPS_SYSTEM_PARTNER: process.env.YELLOWMAPS_SYSTEM_PARTNER,
      YELLOWMAP_TILE_URL: process.env.YELLOWMAP_TILE_URL,
      YELLOWMAP_AUTOCOMPLETE_URL: process.env.YELLOWMAP_AUTOCOMPLETE_URL,
      YELLOWMAPS_SECURITY_ID: process.env.YELLOWMAPS_SECURITY_ID,
      YELLOWMAP_API_URL: process.env.YELLOWMAP_API_URL,
      LOGGING_ENDPOINT: process.env.LOGGING_ENDPOINT,
      LOCALSTORAGE_BOOKMARKS: process.env.LOCALSTORAGE_BOOKMARKS,
      FINANCE_CERTIFICATE_URL: process.env.FINANCE_CERTIFICATE_URL,
    };
  }
}
