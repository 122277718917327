import axios from 'axios';
import { isArray } from 'lodash-es';
import queryString from 'query-string';
import { ParsedUrlQuery } from 'querystring';

import { AfterRegistrationAction } from '../components/LoginHandler/LoginHandler';
import { EstateAdItem } from '../components/UserDashboard/EstateAdList/EstateAdList';
import { LoginProvider } from '../contexts/LoginContext';
import Logger from '../utils/logger';

const axiosConfig = { withCredentials: true };
interface SipUserInfo {
  name: string;
  email: string;
  userId: string;
}

export interface SipUserApiLoginState {
  authenticated: boolean;
  loginInfo?: SipUserInfo;
}

export const extractCode = (data: string | [] | null): string | null => {
  if (typeof data !== 'string') {
    return null;
  }
  const code = queryString.parse(data).code;
  if (typeof code === 'string') {
    return code;
  }
  if (isArray(code) && code.length > 0) {
    return code[0];
  }
  return null;
};

export const getLoginIframeUrl = (loginContext: LoginProvider, afterRegisterParams: ParsedUrlQuery): string => {
  const queryState = afterRegisterParams
    ? JSON.stringify({ redirectUri: loginContext.redirectUri, afterRegisterParams })
    : JSON.stringify({ redirectUri: loginContext.redirectUri });
  const query = new URLSearchParams({
    client_id: loginContext.clientId,
    response_type: 'code',
    redirect_uri: loginContext.redirectUri,
    scope: 'openid',
    state: queryState,
  });
  return `${loginContext.keycloakUrl}/realms/${encodeURIComponent(
    loginContext.realm
  )}/protocol/openid-connect/auth?${query}`;
};

const usedCodes = [];
/**
 * after successfull login keycloak redirects client to //consumer-immobilien/ssoframe/confirm.html?code=abvde.f123456
 * this function gets called with the code from that url by iframe messagehandling
 * Login fails if called twice with same code, duplicate suppression is mandatory
 *
 * @param userApiUrl
 * @param code
 * @param redirectURI
 */
export const loginToUserApi = async (userApiUrl: string, code: string, redirectURI: string): Promise<boolean> => {
  if (usedCodes.includes(code)) {
    return false;
  }
  const body = {
    code,
    redirect_uri: redirectURI,
  };

  try {
    usedCodes.push(code);
    const response = await axios.post(`${userApiUrl}/user/authToken`, body, axiosConfig);
    return response.status < 300;
  } catch (error) {
    logUserApiError(error);
    return false;
  }
};

export const getUserApiLoginState = async (userApiUrl: string): Promise<SipUserApiLoginState> => {
  try {
    const response = await axios.get(`${userApiUrl}/user/details`, axiosConfig);
    const data = await response.data;
    return {
      authenticated: true,
      loginInfo: data,
    };
  } catch (error) {
    logUserApiError(error);
  }
  return { authenticated: false };
};

export type SearchAgentsList = Array<{
  id: number;
  search_params: ParsedUrlQuery;
}>;
export const fetchSavedSearches = async (userApiUrl: string): Promise<SearchAgentsList | null> => {
  try {
    const response = await axios.get(`${userApiUrl}/user/search-agents`, axiosConfig);
    return response.data.requests;
  } catch (error) {
    logUserApiError(error);
    return null;
  }
};

export const addSavedSearch = async (userApiUrl: string, params: ParsedUrlQuery): Promise<SearchAgentsList | null> => {
  try {
    const response = await axios.post(`${userApiUrl}/user/search-agents`, params, axiosConfig);
    return response.data.requests;
  } catch (error) {
    logUserApiError(error);
    return null;
  }
};

export const removeSavedSearch = async (userApiUrl: string, id: number): Promise<SearchAgentsList | null> => {
  try {
    const response = await axios.delete(`${userApiUrl}/user/search-agents/${id}`, axiosConfig);
    return response.data.requests;
  } catch (error) {
    logUserApiError(error);
    return null;
  }
};

function logUserApiError(error) {
  if (!(error.response?.status === 401)) {
    // ToDo: Use logger?
    Logger.error(error);
  }
}

export const addBookmark = async (userApiUrl: string, estateId: string): Promise<boolean> => {
  try {
    const response = await axios.put(`${userApiUrl}/user/estateBookmark/${estateId}`, [], axiosConfig);
    return response.status < 300;
  } catch (e) {
    if (e.response.status !== 401) {
      Logger.error(e);
    }
    return false;
  }
};
export const removeBookmark = async (userApiUrl: string, estateId: string): Promise<boolean> => {
  try {
    const response = await axios.delete(`${userApiUrl}/user/estateBookmark/${estateId}`, axiosConfig);
    return response.status < 300;
  } catch (e) {
    if (e.response.status !== 401) {
      Logger.error(e);
    }
    return false;
  }
};

export const getBookmarks = async (userApiUrl: string): Promise<string[]> => {
  try {
    const response = await axios.get(`${userApiUrl}/user/estateBookmark/`, axiosConfig);
    return response.data;
  } catch {
    return [];
  }
};

export type PriceEstimation = {
  type: string;
  wma: string;
  address: {
    streetName: string;
    streetNumber: string;
    city: string;
    zip: string;
  };
  price?: number;
  link?: string;
  date: string;
};

export const fetchPriceEstimations = async (userApiUrl: string): Promise<Array<PriceEstimation>> => {
  try {
    const response = await axios.get(`${userApiUrl}/user/price-estimator-requests/`, axiosConfig);
    return response.data.requests;
  } catch (e) {
    if (e.response.status !== 401) {
      Logger.error(e);
    }
    return [];
  }
};

export const removePriceEstimation = async (userApiUrl: string, id: string): Promise<boolean> => {
  try {
    const response = await axios.delete(`${userApiUrl}/user/price-estimator-requests/${id}`, axiosConfig);
    return response.status < 300;
  } catch (e) {
    if (e.response.status !== 401) {
      Logger.error(e);
    }
    return false;
  }
};

export const saveAfterRegistrationAction = async (
  userApiUrl: string,
  afterRegistrationAction: AfterRegistrationAction
): Promise<boolean> => {
  try {
    const response = await axios.put(
      `${userApiUrl}/user/after-registration/${afterRegistrationAction.hash}/${afterRegistrationAction.type}`,
      JSON.stringify(afterRegistrationAction.data),
      {
        ...axiosConfig,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response.status < 300;
  } catch (e) {
    if (e.response.status !== 401) {
      Logger.error(e);
    }
    return false;
  }
};

export const performAfterRegistrationAction = async (userApiUrl: string, hash: string): Promise<boolean> => {
  try {
    const response = await axios.get(`${userApiUrl}/user/after-registration/${hash}/`, axiosConfig);
    return response.status < 300;
  } catch (e) {
    if (e.response.status !== 401) {
      Logger.error(e);
    }
    return false;
  }
};

export const activateAppToken = async (userApiUrl: string, activationToken: string): Promise<boolean> => {
  try {
    const response = await axios.patch(`${userApiUrl}/app-token/activate/${activationToken}`, null, axiosConfig);
    return response.status < 300;
  } catch (e) {
    if (e.response.status !== 401) {
      Logger.error(e);
    }
    return false;
  }
};

export const removeUploadedImage = async (
  userApiUrl: string,
  id: string,
  imageName: string,
  userEstateId: string
): Promise<boolean> => {
  try {
    const response = await axios.delete(
      `${userApiUrl}/user/${id}/estate/${userEstateId}/image/${imageName}`,
      axiosConfig
    );
    return response.status < 300;
  } catch (e) {
    if (e.response.status !== 401) {
      Logger.error(e);
    }
    return false;
  }
};

export const fetchEstates = async (userApiUrl: string, userId: string): Promise<Array<EstateAdItem>> => {
  try {
    const response = await axios.get(`${userApiUrl}/user/${userId}/estate/`, axiosConfig);
    return response.data.estates;
  } catch (e) {
    if (e.response.status !== 401) {
      Logger.error(e);
    }
    return [];
  }
};

export const deleteEstate = async (userApiUrl: string, id: string, userEstateId: string): Promise<boolean> => {
  try {
    const response = await axios.delete(`${userApiUrl}/user/${id}/estate/${userEstateId}`, axiosConfig);
    return response.status < 300;
  } catch (e) {
    if (e.response.status !== 401) {
      Logger.error(e);
    }
    return false;
  }
};
