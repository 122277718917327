import React, { FC, useEffect, useState } from 'react';
import { useLocalstorage } from 'rooks';

import { getBookmarks, STORAGE_KEY_BOOKMARKS } from '../utils/bookmarks';

export type BookmarkState = {
  bookmarks: Array<string>;
  loadBookmarks: () => void;
};

export const BookmarkContext = React.createContext<BookmarkState>({
  bookmarks: [],
  loadBookmarks: () => null,
});

export const BookmarkProvider: FC = (props) => {
  const [bookmarks, setBookmarks] = useState([]);
  const [storedBookmarks] = useLocalstorage(STORAGE_KEY_BOOKMARKS, '');

  const loadBookmarks = () => {
    setBookmarks(getBookmarks());
  };

  const bookmarkContext: BookmarkState = {
    bookmarks,
    loadBookmarks,
  };

  useEffect(() => {
    loadBookmarks();
  }, [storedBookmarks]);

  return <BookmarkContext.Provider value={bookmarkContext}>{props.children}</BookmarkContext.Provider>;
};

BookmarkProvider.displayName = 'BookmarkProvider';
