import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import pino, { Level, LoggerOptions } from 'pino';

import Storage from './storage';

const LOGGING_STORAGE_KEY = 'logsEnabled';

const send = (data) => {
  const ENDPOINT = ConfigProvider.getConfig().get('LOGGING_ENDPOINT');

  data.route = 'logging';

  fetch(ENDPOINT, { method: 'POST', body: JSON.stringify(data) });
};

const initLogger = () => {
  let enabled = Storage.get(LOGGING_STORAGE_KEY);
  const queryString = typeof window !== 'undefined' ? window.location.search : '';
  const urlParams = new URLSearchParams(queryString);

  if (urlParams.has('enableLogs')) {
    const enableLogs = urlParams.get('enableLogs');
    enabled = Boolean(+enableLogs || enableLogs === 'true');
    Storage.set(LOGGING_STORAGE_KEY, enabled);
  }

  const pinoConfig: LoggerOptions = {
    mixin() {
      return { source: 'server' };
    },
    browser: {
      asObject: true,
      write(log: any) {
        const { level, msg } = log;
        const lvl = pino.levels.labels[level] as Level;
        const serverLvl = ['error', 'fatal'];
        const data = Object.assign({ type: lvl, source: 'client' }, log);

        // If browser ...
        if (enabled) {
          if (lvl === 'fatal') {
            //eslint-disable-next-line no-console
            console.error(`${lvl.toUpperCase()}:`, msg || log);
          } else {
            //eslint-disable-next-line no-console
            console[lvl](`${lvl.toUpperCase()}:`, msg || log);
          }
        }

        if (serverLvl.includes(lvl)) {
          send(data);
        }
      },
    },
  };

  const logger = pino(pinoConfig);
  return logger;
};

const Logger = initLogger();

export default Logger;
