import axios, { AxiosResponse } from 'axios';
import { stringifyUrl } from 'query-string';

import { ApiClient } from '../general/ApiClient';
import {
  AutoCompleteOption,
  EstateContactMessageProps,
  EstateCountResponse,
  EstateDetail,
  EstateListResponse,
  EstateSearchProps,
  FinanceData,
  UserEventData,
} from '../general/ApiClientTypes';

export class FrontendApiClient implements ApiClient {
  public async getEstateDetail(): Promise<EstateDetail> {
    throw new Error('Method not implemented.');
  }

  public async getEstates(query: any): Promise<EstateListResponse> {
    const url = stringifyUrl({ url: `/api/search`, query }, { arrayFormat: 'bracket' });

    const estatesResponse = await axios.get(url);

    return estatesResponse.data as EstateListResponse;
  }

  public async getEstateCount(searchParams: EstateSearchProps): Promise<EstateCountResponse> {
    const url = stringifyUrl({ url: `/api/estate-total-items`, query: searchParams }, { arrayFormat: 'bracket' });

    try {
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  public async getAutoCompleteOptions(term: string): Promise<Array<AutoCompleteOption>> {
    const response = await axios.get(`/api/autocomplete?term=${term}`);

    return response.data;
  }

  public async getLoans(query: FinanceData): Promise<AxiosResponse> {
    const uri = stringifyUrl({ url: '/api/loans', query });
    const response = axios.get(uri);

    return response;
  }

  public async clientSendEstateContactMessage(contactMessage: EstateContactMessageProps): Promise<boolean> {
    const uri = stringifyUrl({ url: `/api/contact-form-submit` }, { arrayFormat: 'bracket' });

    try {
      const response = await axios.post(uri, contactMessage, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response?.data?.success || false;
    } catch (e) {
      return false;
    }
  }

  public async sendUserEvent(userEvent: UserEventData): Promise<void> {
    const url = stringifyUrl({ url: `/api/user-event` }, { arrayFormat: 'bracket' });
    try {
      const response = await axios.post(url, userEvent, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (e) {
      return;
    }
  }
}
